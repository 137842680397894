/* You can add global styles to this file, and also import other style files */

body {
    background: url('assets/img/fondo_login.jpg') no-repeat center center fixed;
    background-size: cover;
}

.hidden {
    display: none;
}

.center {
    text-align: center;
}

.red {
    color: red;
}

.green {
    color: green;
}